.moreInfoBlock {
  background: #f9f9f9;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 55px 0px;
    gap: 30px;
    .more__every {
      > img {
        width: 50px;
        height: 50px;
        padding: 0 0 20px 0;
      }
      h5 {
        color: #414141;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.35;
        text-transform: uppercase;
        padding: 0 0 12px 0;
        font-family: 'Roboto', sans-serif;
      }
      p,
      b {
        color: #414141;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        text-transform: uppercase;
        padding: 0 0 12px 0;
        font-family: 'Roboto', sans-serif;
      }
      b {
        font-weight: 800;
      }
    }

    @media screen and (max-width: 600px ){
      margin: 0;
      display: flex;
      flex-direction: column;
      padding: 5px 5px;
      gap: 10px;
    }
  }
}
