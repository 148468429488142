.catalogPage {
  &__inner {
    .topCategory {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 30px 0;

      @media screen and (max-width: 950px) {
        flex-wrap: wrap;
      }
      a {
        display: block;
        width: 200px;
        height: 182px;
        background: #f9f9f9;
        border-radius: 5px;
        position: relative;
        @media screen and (max-width: 600px) {
          width: 190px;
          height: 207px;
        }

        @media screen and (max-width: 410px) {
          width: 175px;
          height: 192px;
        }

        @media screen and (max-width: 360px) {
          width: 165px;
          height: 182px;
        }

        .mainCard-flowersImg {
          position: relative;
          width: 100%;
          height: 183px;
          overflow: hidden;
        }

        .flowersImg {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          transition: transform 0.3s ease;
        }

        .flower-card-img {
          border-radius: 10px;
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }

        .mainCard-flowersImg:hover .flowersImg {
          transform: scale(1.1);
        }

        .actions {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.384);
          color: #fff;
          opacity: 0;
          transition: opacity 0.4s ease-in-out;
        }

        .mainCard-flowersImg:hover .actions {
          opacity: 1;
        }

        .action-icon {
          background: none;
          border: none;
          cursor: pointer;
          padding: 5px;
          color: #fff;
          font-size: 24px;
          margin-bottom: 10px;
        }

        .actions p {
          font-size: 18px;
          margin: 0;
          color: #fff;
          text-align: center;
        }

        .actionsClick {
          background: #fff;
          width: 101%;
          margin: 0 auto;
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 5px;
          padding: 8px 5px;
          i {
            display: block;
            min-width: 9px;
            height: 8px;
            background: #c14e77;
            border-radius: 50%;
          }
          p {
            font-family: "Roboto", sans-serif;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: #414141;
          }
        }
      }
    }
  }
}
