//.callMe {
//  background: #f9f9f9;
//  padding: 33px 63px;
//  border-radius: 8px;
//  //background-image: url('../../assets/images/callMe.png');
//  //background-repeat: no-repeat;
//  //background-position: right;
//  display: flex;
//  align-items: center;
//  flex-direction: column;
//  gap: 15px;
//
//  margin: 40px 0px;
//  > div {
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//  }
//  input {
//    width: 270px;
//    height: 45px;
//    border-radius: 10px;
//    border: solid 1px #e8e8e8;
//    background: #ffffff;
//    padding: 10px;
//    font-size: 16px;
//    font-weight: 500;
//  }
//
//  input::before {
//    content: ' ';
//    position: absolute;
//    width: 44px;
//    height: 100%;
//    background: url(../../assets/icons/phone-icon.svg) no-repeat right center;
//    background-size: auto 18px;
//  }
//
//  h5 {
//    font-size: 25px;
//    font-weight: 500;
//    line-height: 1.3;
//    padding: 0 0 20px 0;
//    color: #414141;
//    text-align: center;
//  }
//  p,
//  b {
//    font-size: 16px;
//    font-weight: 400;
//    line-height: 30px;
//    color: #414141;
//    font-family: 'Roboto', sans-serif;
//    b {
//      background: #fff;
//      padding: 0px 10px;
//      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);
//    }
//  }
//  button {
//    cursor: pointer;
//    position: relative;
//    display: flex;
//    width: 280px;
//    min-height: 65px;
//    color: #fff;
//    font-size: 15px;
//    font-family: 'Roboto', sans-serif;
//    line-height: 20px;
//    text-transform: uppercase;
//    align-items: center;
//    justify-content: center;
//    text-align: center;
//    vertical-align: top;
//    padding: 10px 20px;
//    margin: 0 0 30px 0;
//    border: solid 1px #c14e77;
//    background: #c14e77;
//    border-radius: 5px;
//  }
//}

.callMe {
  background: #f9f9f9;
  padding: 44px 63px;
  border-radius: 8px;
  background-image: url('../../assets/images/callMe.png');
  background-repeat: no-repeat;
  background-position: right;
  margin: 40px 0px;

  @media screen and (max-width: 600px ){
    margin: 0 auto;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    background-size: auto;
    background-position: center;
  }
  > div {
    width: 440px;
    @media screen and (max-width: 600px ){
      width: 93%;
      height: 425px;
      padding: 0;
      margin: 0;
    }
  }
  h5 {
    @media screen and (max-width: 550px ){
      max-width: 100%;
      font-size: 29px;
    }
    font-size: 30px;
    font-weight: 500;
    line-height: 1.3;
    padding: 0 0 20px 0;
    color: #414141;
  }
  p,
  b {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    padding: 0 0 20px 0;
    color: #414141;
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: 550px ){
      font-size: 22px;
      margin-top: 20px;
      max-width: 100%;
    }

    b {
      background: #fff;
      padding: 0px 10px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);
    }
  }
  button {
    cursor: pointer;
    position: relative;
    display: flex;
    width: 280px;
    min-height: 65px;
    color: #fff;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: top;
    padding: 10px 20px;
    margin: 0 0 30px 0;
    border: solid 1px #b42658;
    background: #b42658;
    border-radius: 5px;

    @media screen and (max-width: 550px ){
      margin-top: 40px;
    }
  }
}
