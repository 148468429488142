.menu {
  background-color: #c14e77;
  .main {
    // background: #e9457e;
    padding: 25px 18px;

    .main-menu-link {
      margin-right: 20px;
      position: sticky;
      z-index: 2;
    }
  }

  @media screen and (max-width: 860px) {
    display: none;
    padding: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;

    .main {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      > div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2px;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        i {
          width: 3px;
          height: 3px;
          background: #fff;
          border-radius: 50%;
        }
      }
      p {
        color: #fff;
      }
    }
    .category {
      display: flex;
      align-items: center;
      li {
        color: #fff;
        padding: 25px 14px;
        cursor: pointer;
        position: relative;
        font-size: 15px;

        &:hover {
          &::before {
            content: "";
            background-image: url("../../assets/icons/mainRound.svg");
            background-size: 100% 72%;
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 65%;
            transform: translateY(-50%);
            left: 0;
          }
        }
      }
    }
  }
}
