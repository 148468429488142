.lichnyj-kabinet {
  &__inner {
    padding: 0 10px;
    h2 {
      color: #414141;
      font: 600 25px "Berlingske Serif", sans-serif;
      line-height: 1.35;
      padding: 0 0 29px 0;
      margin: 0;
    }

    .form-login {
      display: flex;
      flex-direction: column;
      gap: 20px;

      form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
      }

      label {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .input {
          width: 100%;
          height: 60px;
          padding: 0 20px;
          font-size: 20px;
          font-weight: 400;
          border: 1px solid #e8e8e8;
          border-radius: 7px;
          outline: none;
        }
      }

      button {
        display: block;
        width: 250px;
        min-height: 65px;
        color: #fff;
        line-height: 20px;
        text-transform: uppercase;
        align-items: center;
        padding: 10px 20px;
        font-size: 18px;
        font-weight: 500;
        background: #c14e77;
        border-radius: 5px;
        margin: 20px 0 0 0;

        @media screen and (max-width: 600px) {
          width: 90%;
          margin: 20px auto;
          z-index: 10;
        }
      }
    }

    .register {
      display: flex;
      flex-direction: column;
      gap: 20px;

      form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
      }

      label {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        input {
          width: 100%;
          height: 60px;
          padding: 0 20px;
          font-size: 20px;
          font-weight: 400;
          border: 1px solid #e8e8e8;
          border-radius: 7px;
          outline: none;
        }
      }

      button {
        display: block;
        width: 250px;
        min-height: 65px;
        color: #fff;
        line-height: 20px;
        text-transform: uppercase;
        align-items: center;
        padding: 10px 20px;
        font-size: 18px;
        font-weight: 500;
        background: #c14e77;
        border-radius: 5px;
        margin: 20px 0 0 0;

        @media screen and (max-width: 600px) {
          width: 90%;
          margin: 20px auto;
          z-index: 10;
        }
      }
    }

    .forgot-form {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}
