.wrapper_profile {
  display: flex;
  flex-direction: column;

  .profile {
    background: #f7f0eb;
    border-radius: 10px;
    height: 350px;
    width: 450px;
    box-shadow: #0000002d 1px 2px 2px 1px;
    &__inner {
      // display: flex;
      // flex-direction: column;
      // align-items: flex-start;
      gap: 20px;
      margin-top: 15px;

      .card_title {
        font-size: 20px;
        text-align: center;
        color: black;
        font-size: 20px;
        align-self: center;
      }

      .item {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 10px;
        border-radius: 7px;
        -webkit-box-shadow: 4px 5px 11px 17px rgba(34, 60, 80, 0.03);
        -moz-box-shadow: 4px 5px 11px 17px rgba(34, 60, 80, 0.03);
        box-shadow: 4px 5px 11px 17px rgba(34, 60, 80, 0.03);

        .item_desc {
          color: black;
        }
      }
    }
  }
  .logout {
    margin-top: 10px;
    color: #c14e77;
    font-size: 18px;
    padding: 15px 40px;
    background: transparent;
    border-radius: 10px;
    border: solid 1px #c14e77;
    transition: 0.4s all;

    &:hover {
      background: #c14e77;
      color: #fff;
    }
  }
}

.modal-footer {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  justify-content: center;
}

.confirm-logout {
  color: #c14e77;
  font-size: 18px;
  padding: 10px 30px;
  background: transparent;
  border-radius: 10px;
  border: solid 1px #c14e77;
  transition: 0.4s all;

  &:hover {
    background: #c14e77;
    color: #fff;
  }
}
.cancel-logout {
  color: #c14e77;
  font-size: 18px;
  padding: 10px 30px;
  background: transparent;
  border-radius: 10px;
  border: solid 1px #c14e77;
  transition: 0.4s all;

  &:hover {
    background: #c14e77;
    color: #fff;
  }
}
