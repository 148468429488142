
.menu-icon{
  margin: 0 auto;
  width: 76px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;


  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      width: 20px;
      height: 20px;
    }

    .menu-title {
      font-size: 14px;
      line-height: 32px;
    }
  }
}


