* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 18px;
  color: #7c7c7c;
  font-family: "Roboto", sans-serif;
  line-height: normal;
  font-weight: 400;
}

///  font-family: "Roboto", sans-serif;
///  font-family: "Playfair Display", serif;
///  font-family: "EB Garamond", serif;
.container {
  // width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;

  @media screen and (max-width: 550px) {
    padding: 0 5px;
  }
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
  height: 100%;
}

button {
  border: none;
  cursor: pointer;
}

.noneData {
  display: none;
}

.viewData {
  display: block;
}

.list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  padding: 30px 0px;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding: 10px 0;
  }
  @media screen and (max-width: 860px) {
    gap: 10px !important;
  }

  @media screen and (max-width: 480px) {
    gap: 7px !important;
  }
}

.absent {
  font-family: "Playfair Display", serif;
  font-size: 30px;
  text-align: center;
  padding: 250px 0;
  font-weight: 600;
  width: 100%;
}

.title {
  font-size: 40px;
  font-family: "EB Garamond", serif;
  padding: 20px 0 10px;
  position: relative;
  display: inline-block;

  &::before {
    content: "";
    background-image: url("./assets/icons/mainRound.svg");
    background-size: 150px;
    background-repeat: no-repeat;
    position: absolute;
    transform: translateY(-50%);
    width: 150px;
    height: 70px;
    right: -46px;
    transform: rotate(200deg);
    top: 20%;

    @media screen and (max-width: 780px) {
      display: none;
    }
  }
}

.star {
  width: 80px;
  height: 13px;
  display: flex;
  justify-content: flex-end;

  > img {
    width: 12px;
    height: 12px;
  }

  b {
    font-size: 14px;
    line-height: 14px;
  }
}

.navigateWeb {
  border-bottom: 1px solid #ebedea;
  padding: 15px 0px;
  margin: 30px 0 30px 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    margin: 0;
  }

  a {
    display: block;
    color: #bebebe;

    &:hover {
      color: #c14e77;
      transition: 1s;
    }

    &::after {
      content: ">";
      margin: 15px;
    }
  }
}
