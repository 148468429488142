.mobile-menu {
  display: none;
  @media screen and (max-width: 860px ){
    display: block;
  }

  &__inner {
    .mobile-sdm {
      cursor: pointer;
      position: relative;
      display: block;
      width: 81px;
      height: 81px;

      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../assets/icons/dots-menu.svg) no-repeat center center;
        background-size: auto 30px;
        background-color: #c14e77;
      }
    }
  }
}
