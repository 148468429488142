.preFooter {
  background: #f7f0eb;
  padding: 10px 0px;
  border-bottom: 1px solid #ebe4df;
  margin: 20px 0 0 0;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 130px;
      height: 40px;
    }
    ul {
      display: flex;
      align-items: center;
      li {
        color: #414141;
        font-size: 13px;
        padding: 18px 14px;
        cursor: pointer;
      }
    }
  }

  .soc_icons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .links {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 655px) {
    display: none;
  }
}

.nav {
  background: #f7f0eb;
  &__inner {
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > p {
      font-size: 16px;
      line-height: 19px;
      color: #c14e77;
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        transform: scale(1.02);
        text-decoration: none;
      }
    }
  }
  .contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    p {
      font-size: 14px;
      line-height: 19px;
      color: #414141;
    }
    > div {
      cursor: pointer;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #fff;
      &:hover {
        background: #e6d8cf;
        transition: 0.5s;
      }
       >img {
        width: 50%;
        height: 50%;
      }
    }
  }
}

footer {
  padding: 20px 0px;
  background: #c14e77;

  @media screen and (max-width: 600px) {
    padding-bottom: 90px;

    .footerMain{
      display: flex;
      flex-direction: column;
      gap: 40px;
      justify-content: center;
      align-items: center;
    }

    .contactsFooter{
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
    }
  }

  .footerMain {
    display: flex;
    justify-content: space-between;
    h6 {
      font-size: 16px;
      line-height: 16px;
      color: #fff;
      margin: 0 0 5px 0;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      color: #fff;
    }
    .contactsFooter {
    }
  }
}
