.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.13);
  // z-index: 50;
}

// .menu-item:nth-child(1)
//   .submenu-list
//   .submenu-item:nth-child(1)
//   .sub-submenu-item:nth-child(1) {
//   display: none;
// }
// .menu-item:nth-child(4)
//   .submenu-list
//   .submenu-item:nth-child(4)
//   .sub-submenu-item:nth-child(4) {
//   display: none;
// }

.menu {
  position: relative;
}

.menu-title {
  color: #fff;
  position: sticky;
  top: -10px;
  // right: 100px;
  left: -20px;
  text-align: center;
  z-index: 2;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-top: 21px;
  margin-left: 170px;
}

.nav-link {
  color: #000;
  transition: 0.3s all;

  &:hover {
    color: #c14e76;
  }
}

.menu-item {
  position: relative;
  padding: 20px 12px;
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 1px solid #c14e7611;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding-top: 20px;

  &:hover {
    // background-color: #e0e0e0;
    color: #fff;
  }
}

.arrow {
  font-size: 14px;
  color: #000;
}

.submenu-list,
.sub-submenu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 100;
  min-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu-item:hover > .submenu-list,
.submenu-item:hover > .sub-submenu-list {
  display: block;
}

.submenu-item,
.sub-submenu-item {
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;

  &:hover {
    color: #c14e76;
    transition: 0.3s all;
  }
}

.menu-item:last-child,
.submenu-item:last-child,
.sub-submenu-item:last-child {
  border-bottom: none;
}
