.personal-account {
  .container {
    // background: #000;
    max-width: 1700px;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 100vh;

    .order_history {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 60%;
    }

    .order_history table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        border: 1px solid #dddddd;
        padding: 8px;
        text-align: left;
      }

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      th {
        background-color: #dddddd;
      }
    }
  }
}
