.maincontent {
  &__inner {
    padding: 30px 0;
    text-align: left;

    > button {
      font-size: 20px;
      line-height: 1.1;
      text-transform: none;
      align-items: center;
      padding: 0 20px;
      width: 250px;
      height: 54px;
      background: #c14e77;
      color: #fff;
      border-radius: 8px;

      &:hover {
        cursor: pointer;
        background: #b42658;
        transform: scale(1.01);
        transition: 0.5s;
      }
    }

    .priceSort {
      background: #f9f9f9;
      padding: 30px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 25px;
      margin: 20px 0 0 0;
      border-radius: 8px;

      &__shadow {
        position: relative;
      }

      &__every {
        padding: 15px 20px;
        background: #fff;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
      }

      .activePrice {
        background: #c14e77;
        color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      }

      .activeImg {
        width: 25px;
        height: 25px;
        position: absolute;
        background: #b42658;
        top: -10px;
        right: -10px;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}

.categoryType {
  padding: 20px 0px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;

  li {
    padding: 10px 15px;
    border: solid 1px #b42658;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 7px;
    justify-content: center;
    cursor: pointer;

    p {
      color: #b42658;
    }

    &:hover {
      background: #b42658;

      p {
        color: #fff;
      }
    }

    &:nth-child(4) {
      img {
        width: 19px;
      }
    }

    img {
      display: block;
      width: 24px;
      height: 24px;
    }
  }

  .activeType {
    background: #b42658;

    p {
      color: #fff;
    }
  }

  @media screen and (max-width: 860px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 480px) {

  }
}
