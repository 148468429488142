.swiper {
  // width: 50vw;
  max-width: 1200px;
  margin-top: 5px;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  height: 500px;

  &__inner {
    .mySwiper {
      width: 100%;
      height: 100%;
      padding: 5px;
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }
}
