.mainPage {
  .shadow {
      width: 100%;
      height: 148px;
  }
  .menuMain {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99;
  }


  @media screen and (max-width: 860px ){
    .menuMain {
      padding: 0 1px;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      margin: 0;
      align-items: center;
    }

    .shadow{
      height: 90px ;
    }
  }

  .navigate_panel{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
