.alertBlock {
    background: pink;
  .text {
    color: #ffffff;
    font-family: Gilroy;
    font-size: 54px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
