.sendZakaz {
    width: 350px;
    padding: 23px 26px 12px 26px;
    background: #fff;
    box-shadow: 0 5px 20px rgba(65, 65, 65, 0.31);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
    // background: red;
    padding: 20px;
    h5 {
      font-size: 25px;
      color: #414141;
      font-weight: 600;
      font-family: "EB Garamond", serif;
    }
    p {
      font-size: 15px;
      color: #898989;
      font-weight: 600;
    }
    input {
      height: 40px;
      width: 100%;
      border: 1px solid #afaaaa;
      padding: 0 15px;
      border-radius: 3px;
      margin: 20px 0px;
    }
    button {
      min-width: 73px;
      width: 100%;
      height: 40px;
      color: #fff;
      font-size: 15px;
      text-align: center;
      text-transform: uppercase;
      border: none;
      background: #c14e77;
      border-radius: 3px;
    }
    .activeImgOneClick {
        width: 30px;
        height: 30px;
        position: absolute;
        background: #b42658;
        top: -10px;
        right: -10px;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
    }
  }

@media screen and (max-width: 480px ){
  .sendZakaz {
    width: 95vw;

  }
}

@media screen and (max-width: 860px ){

}

